.mobile.simple-keyboard.hg-layout-default .hg-button {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    align-items: center;
    background: #FFF;
    border-radius: 5px;
    box-shadow: 0 0 3px -1px rgb(0 0 0 / 30%);
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-size: 2.4rem;
    border-radius: 1.2rem;
    height: 7rem;
    justify-content: center;
    padding: 0px;
    margin: 0.3rem;
    font-weight: 500;
}

.mobile.simple-keyboard.hg-layout-default .hg-button.hg-bksp {
  width: 1.2vw;
  background-image: url("../../../../assets/images/kiosk-bksp.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40% 40%;
  background-color: #626367;
}

.mobile.simple-keyboard.hg-layout-default .hg-button.hg-bksp >span{
  visibility: hidden;
  width: 1.2vw;
}
.mobile.hg-theme-default .hg-row:nth-child(2){
  padding: 0 !important;
}

.mobile.hg-theme-default .hg-row .hg-button.hg-bksp{
  width: 1.2vw;
  background-image: url("../../../../assets/images/kiosk-bksp.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40% 40%;
  background-color: #626367;
}

.mobile.hg-theme-default .hg-row .hg-button.hg-bksp > span{
  visibility: hidden;
  width: 1.2vw;
}

.mobile.hg-theme-default {
    background-color: transparent !important;
    border-radius: 5px;
    box-sizing: border-box;
    font-family: HelveticaNeue-Light,Helvetica Neue Light,Helvetica Neue,Helvetica,Arial,Lucida Grande,sans-serif;
    overflow: hidden;
    padding: 5px;
    touch-action: manipulation;
    -webkit-user-select: none;
    user-select: none;
    width: 100%;
}
.simple-keyboard.hg-layout-default .hg-button.hg-bksp {
    width: 2vw
  }

